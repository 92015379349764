
import { Component, Vue } from "vue-property-decorator";
import { AddressService, GoodsOrderService, healthOrderService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import { fail } from "assert";
import globalConfig from "src/config/index";

@Component
export default class GoodsDetail extends Vue {
  /**
   * 是否显示确认退款弹框
   * @private
   * @returns
   */
  private showDialog: boolean = false;

  /**
   * 是否请求中
   * @private
   * @returns
   */
  private loading: boolean = false;

  /**
   * 是否显示确认收货弹框
   * @private
   * @returns
   */
  private showPopup: boolean = false;

  /**
   * 数据模型
   * @private
   * @returns any
   */
  private dataModel: any = {
    actualAmount: 0,
    address: "",
    addressId: null,
    areaId: null,
    areaName: "",
    cancelTime: null,
    cityId: null,
    cityName: "",
    completedTime: null,
    createdTime: "",
    expressCompany: null,
    expressNo: "",
    id: null,
    isOvertimeUnPay: null,
    marketPrice: 0,
    orderNo: "",
    payedTime: null,
    phone: "",
    price: 0,
    productId: null,
    productName: "",
    productPict: "",
    productQty: null,
    productTypeStr: "",
    provinceId: null,
    provinceName: "",
    receiverName: "",
    refundedTime: null,
    refundingTime: null,
    shippedTime: null,
    sourceKind: 2,
    sourceKindStr: "",
    status: null,
    statusStr: "",
    supplierId: null,
    supplierLogo: "",
    supplierName: "",
    unitMarketPrice: null,
    unitPrice: null,
    isFromRights: false,
    rightsId: null,
    rightsName: "",
  };

  /**
   * 商品详情id
   * @private
   * @returns string
   */
  private get id(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 地址id
   * @private
   * @returns string
   */
  private get addressId(): string {
    return (this.$route.query && (this.$route.query.addressId as string)) || "";
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 跳转商品详情
   * @private
   * @returns string
   */
  private gomedicalDetail(): void {
    // this.$router.push({name:"medical", query:{id: this.dataModel.productId}});
  }
  /**
   * 组件创建钩子
   * @protected
   * @returns void
   */
  protected created() {
    this.getAddressList();
  }

  /**
   * 地址列表
   * @private
   * @returns number
   */
  private addressList: Array<any> = [];

  /**
   * 获取地址列表
   * @private
   * @returns void
   */
  private async getAddressList() {
    try {
      this.getGoodsOrderDetail();
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 获取产品下单前数据
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async getGoodsOrderDetail(): Promise<void> {
    try {
      let { content: result } = await healthOrderService.instance.getHealthInsDetail(this.id);
      if (result.data) {
        this.dataModel = result.data;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 取消订单
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async onCancel(): Promise<void> {
    try {
      if (this.loading) {
        return;
      }

      this.loading = true;
      await GoodsOrderService.instance.cancelGoodsOrder(this.id);
      if (this.dataModel.status == 4) {
        Dialog.confirm({
          message: "是否取消订单？",
        })
          .then(() => {
            Toast("取消订单成功");
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.showDialog = false;
        Toast("提交成功");
      }
      this.loading = false;
      this.getGoodsOrderDetail();
    } catch (err) {
      this.loading = false;
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 确认收货
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public async confirmGoodsOrder(): Promise<void> {
    try {
      await GoodsOrderService.instance.confirmGoodsOrder(this.id);
      Toast("操作成功");
      this.getGoodsOrderDetail();
      this.showPopup = false;
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 撤销申请
   * @public
   * @param {params}
   * @returns {Promise<IHttpResponse>}
   */
  public onRevoke(): void {
    try {
      Dialog.confirm({
        message: "您将撤销本次申请，如果问题未解决，您还可以再次发起。确定继续吗？",
      })
        .then(async () => {
          await GoodsOrderService.instance.onRevokeGoods(this.id);
          this.getGoodsOrderDetail();
          Toast("已撤销申请");
        })
        .catch(() => {
          // on cancel
        });
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 继续支付
   * @private
   * @param {params}
   * @returns {Promise<void>}
   */
  private async proceedPay(): Promise<void> {
    this.$router.push({ name: "pay", query: { orderNo: this.dataModel.orderNo } });
    // try
    // {
    //     let {content: result} =  await GoodsOrderService.instance.proceedpayGoodsOrder(this.id);

    //     if(result)
    //     {
    //         if(result.data.isNeedPay)
    //         {
    //             this.$router.push({name: "pay", query:{orderNo: result.data.orderNo}});
    //             // Pay.onBridgeReady(result.data, this.getGoodsOrderDetail);
    //         }
    //         else
    //         {
    //             this.getGoodsOrderDetail();
    //         }

    //     }
    // }
    // catch(err)
    // {
    //     Toast(err);
    // }
  }

  /**
   * 确认订单
   * @private
   * @param {params}
   * @returns {Promise<void>}
   */
  private async onConfirmOrder(): Promise<void> {
    try {
      if (!this.dataModel.addressId) {
        Toast("请选择收货地址");
        return;
      }

      let { content: result } = await GoodsOrderService.instance.confirmGoodsOrderInfo({
        orderId: this.id,
        addressId: this.dataModel.addressId,
      });
      this.getGoodsOrderDetail();
      Toast("操作成功");
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 修改地址
   * @private
   * @param {params}
   * @returns {Promise<void>}
   */
  private async onUpdateAddress(): Promise<void> {
    // 待确认或者待收货可以修改地址
    if (this.dataModel.status == 2 || this.dataModel.status == 6) {
      if (!this.addressList.length) {
        // 传入当前订单的id
        this.$router.push({ name: "address-detail", query: { orderId: this.id } });
      } else {
        this.$router.push({
          name: "goods-order-update-address",
          query: { id: this.id, addressId: this.dataModel.addressId, status: this.dataModel.status },
        });
      }
    }
  }

  /**
   * 复制
   * @param str
   */
  private async copyOrderNo(str): Promise<void> {
    let save = function (e) {
      e.clipboardData.setData("text/plain", str);
      e.preventDefault();
    };
    let input = document.createElement("input");
    input.value = str;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, input.value.length), document.execCommand("Copy");
    document.body.removeChild(input);
    Toast({
      message: "复制成功",
      duration: 1000,
    });
  }
}
